import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
//import { ToastrModule } from 'ngx-toastr';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import {MatCardModule} from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatTableModule} from '@angular/material/table';
import { AddComponent } from './add/add.component';
import { DataService } from './data.service';
import { NotificationService } from '../../services/notificationService';
import { NgxPaginationModule } from 'ngx-pagination';
import { PurchaseOrderComponent } from './purchase-order.component';
import { PurchaseOrderRoutingModule } from './purchaseorder.routing';
import { EditComponent } from './edit/edit.component';


@NgModule({
    declarations: [
      PurchaseOrderComponent,
        AddComponent,
        EditComponent
    ],
    providers: [DataService,NotificationService],
    imports: [

        PurchaseOrderRoutingModule,
        CommonModule,
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot(),
        BsDropdownModule.forRoot(),
        CollapseModule.forRoot(),
        FormsModule,
        NgxPaginationModule,
        ReactiveFormsModule,
        MatCheckboxModule,
       // ToastrModule.forRoot(),
        MatListModule,
        MatIconModule,
        MatTableModule,
        MatCardModule,
       // MatPaginator,
        MatPaginatorModule,
        //MatTableDataSource,
        MatProgressBarModule,
        MatGridListModule,
        AutocompleteLibModule,
        
       
        
    ]
})
export class PruchaseOrderModule{ }
