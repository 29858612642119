import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
//import { AuthService } from '../../../services/auth.service';
import { NotificationService } from '../../../services/notificationService';
import { DataService } from '../data.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss'
})
export class EditComponent {
  project: any = {};
  next:boolean = false;
  states: string[] = ['State 1', 'State 2', 'State 3'];
  purchaseMode:string[]=['debit','credit']
  paymentMode:string[]=['cash','checque']
  purchaseOrderForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
  bill:string|undefined;
  total:any[]=[];
  uid:any;
  id:any;
  keyword1 = 'projectName'
  keyword2 = 'siteNo'
  keyword3 = 'engineer'
  keyword4 = 'supplierName'
  keyword5 = 'productName'
  product:string[]=[]
  ispurchase:boolean = false;
  ispayment:boolean = false;
  isEnabled:boolean = false;
  isDisabled = false;
  productName:any[]=[];
  projectName:any[]=[];
  siteNo:any[]=[];
  engineer:any[]=[];
  // rows: FormGroup[];
  // productDetails:any[]=[];
  supplierName:any[]=[];
  gst=false;
  btnEnable:boolean=false;
  iscredit:boolean=false;
  isdebit:boolean=false;
  iscash:boolean=false;
  ischeque:boolean=false;
  category:any[]=[];
 
  

  constructor(private data: DataService,
    private toastr:NotificationService,
    private formBuilder: FormBuilder,
    private router:Router,

   // private auth:AuthService,
    private route:ActivatedRoute
  ) {
  
  }

  ngOnInit()  {
    if (this.route.snapshot.queryParamMap.get("id")) {
      // @ts-ignore TS2322
      this.id = this.route.snapshot.queryParamMap.get("id");
      this.getById(this.id);
    }

    this.purchaseOrderForm = this.formBuilder.group({
      pocode: [{ value: '', disabled: true }],
      supplierName: ['', Validators.required],
      orderDate: ['', Validators.required],
      needDate: ['', Validators.required],
      projectName: ['', Validators.required],
      siteNo: ['', Validators.required],
      engineer: ['', Validators.required],
      totalProduct: [{ value: '', disabled: true }],
      productDetails: this.formBuilder.array([this.createRow()])
    });

    this.generatePOCode();
    this.getDropdownData();
   
   
  }
  get productDetails(): FormArray {
    return this.purchaseOrderForm.get('productDetails') as FormArray;
    
  }
  
  createRow(): FormGroup {
    return this.formBuilder.group({
      productName: [''],
      quantity: [''],
      description: ['']
    });
  }
  
  addRow(): void {
    this.productDetails.push(this.createRow());
  }
  onNext(){
    this.next = true
  }
  
  removeRow(index: number): void {
      this.productDetails.removeAt(index);
  }
  
  generatePOCode(): void {
    const newId = uuidv4().substring(1,5);
    this.purchaseOrderForm.get('pocode')?.setValue(newId);
  }
  
  getDropdownData(): void {
    // Your existing code to fetch productName, projectName, and supplierName
  }
  
  onSubmit() {
    this.submitted = true;
  
    // if (this.purchaseOrderForm.invalid) {
    //   return;
    // }
  
    const formValue = this.purchaseOrderForm.getRawValue();
    const productDetails = formValue.productDetails;
    console.log(productDetails,"productDetails")
    //formValue.companyName = "Aura Contrivers"
    formValue.updateOn = moment().format()
    //   data.engineerName = "Raman"
    //   data.productDetails = this.productDetails
   // formValue.isActive = true
    console.log(formValue,"formValue")
    this.data.update(formValue,this.id).subscribe(
      (response) => {
         console.log('Order created successfully', response);
         this.toastr.showSuccess('Order created successfully!',"");
         this.router.navigate(['/dashboard/purchaseOrder']);
       },
       (error) => {
        console.error('Error creating order', error);
        this.toastr.showError('Error creating order',"");
      }
   );
  }
  
 // get productDetails() { return this.purchaseOrderForm.get('productDetails') as FormArray; }
 getById(_id:any){
  this.data.getById(_id).subscribe((res:any)=>{
    console.log(res,"res")
   if(res){
    //console.log(this.categoryData,"concel")
    this.updateView(res)
    this.category.push(res)
   }
  })
  
}
updateView(data:any){
  console.log(data,"Edit")
  this.purchaseOrderForm.patchValue({
    companyName: data.companyName,
    pocode: data.pocode,
    orderDate: data.orderDate,
    supplierName: data.supplierName,
    needDate: data.needDate,
    projectName: data.projectName,
    siteNo: data.siteNo,
    engineerName:data.engineerName,
    totalProduct: data.totalProduct,
  })
  // this.category.forEach((val:any)=>{
  //   val.productDetails.forEach((prd:any)=>{
  //    const datas = {
  //     productName:prd.productName,
  //     quantity:prd.quantity,
  //     description:prd.description
  //    }
  //    this.rows.concat(datas)
  //   })
  // })
}

  // addRow(){
  //   const row = this.formBuilder.group({
  //     productName: ['', Validators.required],
  //     quantity: [0, Validators.required],
  //     description: ['', Validators.required]
  //   });
  //   this.rows.push(row);
    
  // }
  // add() {
  //     this.addRow();
  // }
  getAllData(){
    this.productDetails.push({
      productName:this.purchaseOrderForm.get('productName')?.value,
      quantity:this.purchaseOrderForm.get('quantity')?.value,
      description:this.purchaseOrderForm.get('description')?.value
    })
  }

  // remove(index: number) {
  //   if (this..length > 1) {
  //     this.rows.splice(index, 1);
  //   }
  // }
  onCancel(){
    this.purchaseOrderForm.reset()
  }


  goBack() {
    this.router.navigate(['/dashboard/purchaseOrder']);
  }
  get f() {
    return this.purchaseOrderForm.controls;
  }
//   onSubmit(data: any) {
//     console.log(data)
//     if (this.purchaseOrderForm.invalid) {
//       this.toastr.showError("Error", "");
//       return;
//     }
// data.companyName = "Aura Contrivers"
//     data.postOn = moment().format()
//     data.engineerName = "Raman"
//     data.productDetails = this.productDetails
//     data.isActive = true
//     console.log(data,"formData")
//     this.data.create(data).subscribe((res:any)=>{
//       console.log('Purchase Created!',res);
//       this.toastr.showSuccess("Successfully!!","Purchase Added")
//       this.router.navigate(['/dashboard/purchaseOrder'])
//     })
//   }
  handleFileImage(files: FileList) {
    // @ts-ignore TS2531
    const fileToUpload: File = files.item(0);
    const reader = new FileReader();
    const fileValue = new Promise(resolve => {
      reader.onload = () => {
        this.bill = reader.result as any;
        // @ts-ignore TS2532
        if (this.bill.length * 2 > 2 ** 21) {
          alert("File exceeds the maximum size");
        } // Note: 2*2**20 = 2**21
        resolve(reader.result);
      };
    });
    reader.readAsDataURL(fileToUpload);
    return fileValue;
  }

  selectEvent(item:any) {
    // do something with selected item
    console.log(item)
   
    this.purchaseOrderForm.patchValue({
      productName:item.productName,
    })
   
   // const selectedCategory = item.productName
    const supplierName = item.supplierName;
    const existingSupplier = this.purchaseOrderForm.get('supplierName')?.value;

    if (existingSupplier && existingSupplier !== supplierName) {
      this.purchaseOrderForm.get('supplierName')?.setValue(`${existingSupplier}, ${supplierName}`);
    } else {
      this.purchaseOrderForm.get('supplierName')?.setValue(supplierName);
    }

   
    console.log(this.productDetails)
  }
 
  onChangeSearch(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getProduct().subscribe((res:any)=>{
      this.productName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
      console.log(this.product)
      //this.productName = [];
      
       //this.product.forEach((val:any)=>{
        return this.productName.filter(val  =>  val.toLowerCase().includes(search));
      //   if (val.productName && val.productName.toLowerCase().trim() === search.toLowerCase().trim()) {
      //     this.productName.push(val);
      //     console.log(this.productName);
      // }
      //})

    })
  }

  onFocused(e:any) {
    console.log(e)
    // do something
  }
  selectEvent3(item:any) {
    // do something with selected item
    console.log(item)
   
    this.purchaseOrderForm.patchValue({
      productName:item.productName,
      shortName:item.shortName,
      supplierName:item.supplierName
    })
  }
 
  onChangeSearch3(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getProduct().subscribe((res:any)=>{
      this.productName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
      console.log(this.product)
      //this.productName = [];
      
       //this.product.forEach((val:any)=>{
        return this.productName.filter(val  =>  val.productName.toLowerCase().includes(search));
      //   if (val.productName && val.productName.toLowerCase().trim() === search.toLowerCase().trim()) {
      //     this.productName.push(val);
      //     console.log(this.productName);
      // }
      //})

    })
  }

  onFocused3(e:any) {
    console.log(e)
    // do something
  }
  selectEvent1(item:any) {
    // do something with selected item
    console.log(item)
   
    this.purchaseOrderForm.patchValue({
      projectName:item.projectName
    })
  }
 
  onChangeSearch1(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getSite().subscribe((res:any)=>{
      this.projectName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
          console.log(this.projectName)
        return this.projectName.filter( val  =>   val.toLowerCase().includes(search));
    })
  }

  onFocused1(e:any) {
    console.log(e)
    // do something
  }
  selectEvent4(item:any) {
    // do something with selected item
    console.log(item)
    
   
    this.purchaseOrderForm.patchValue({
      supplierName:item.supplierName
    })
    this.data.getProduct().subscribe((res:any)=>{
      this.productName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
      this.productName.forEach((val:any)=>{
        if(val.supplierName.toLowerCase() == item.supplierName.toLowerCase()){
        this.category.push(val)
        console.log(this.category)
        }
      })
    })
  }
  getData(){
    this.total.push(this.purchaseOrderForm.get('productName')?.value)
    console.log(this.total.length)
    this.purchaseOrderForm.patchValue({
      totalProduct:this.total.length
    })
  }
 
  onChangeSearch4(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getSupplier().subscribe((res:any)=>{
      this.supplierName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
        return this.supplierName.filter( val  =>   val.supplierName.toLowerCase().includes(search));
    })
  }

  onFocused4(e:any) {
    console.log(e)
    // do something
  }
  fieldEnable(){
   this.btnEnable = true
  }
  calculate(){
    this.total = this.total || 0
    const product = this.purchaseOrderForm.get('productName')?.value
    console.log(product)
   }

  enabled(){
    console.log(this.isEnabled)
    if(this.isEnabled === true){
    this.purchaseOrderForm.patchValue({
      cstatus:true
    })
  } else{
    this.purchaseOrderForm.patchValue({
      cstatus:false
    })
  }
  }
  selectEvent5(item:any,i:any) {
    // do something with selected item
    console.log(item)
   
    this.productDetails.at(i).patchValue({
      productName:item.productName
    })
  }
 
  onChangeSearch5(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getproduct().subscribe((val:any)=>{
      this.productName = Object.keys(val).map(key => ({ id: key, ...val[key] }));
      console.log(this.productName)
      return this.productName.filter(val => val.productName.toLowerCase().includes(search))
    })
  }

  onFocused5(e:any) {
    console.log(e)
    // do something
  }
}
