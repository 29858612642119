<div class="form-container">
  <button class="back-button" (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
  </button>
  <div class="row">
    <div class="col-lg-12 col-sm-12 col-md-12">
      <h2>New PurchaseOrder</h2>
      <form [formGroup]="purchaseOrderForm">    
        <h3>Product Details</h3>
        <div class="card">
          <div class="form-row">
            <div class="scroll1">    
              <table class="custom-table">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody formArrayName="productDetails">
                  <tr *ngFor="let row of productDetails.controls; let i = index" [formGroupName]="i">
                    <td>
                      <ng-autocomplete
                        [data]="productName"
                        [searchKeyword]="keyword5"
                        placeholder="Enter the productName"
                        (selected)='selectEvent5($event,i)'
                        (inputChanged)='onChangeSearch5($event)'
                        (inputFocused)='onFocused5($event)'
                        historyIdentifier="productName"
                        (click)="getAllData()"
                        [itemTemplate]="itemTemplate7"
                        [notFoundTemplate]="notFoundTemplate">
                      </ng-autocomplete>
                      <ng-template #itemTemplate7 let-item>
                        <a [innerHTML]="item.productName"></a>
                      </ng-template>
                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </td>
                    <td>
                      <input
                        type="number"
                        placeholder="Enter quantity"
                        class="form-control"
                        formControlName="quantity"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        placeholder="Enter description"
                        class="form-control"
                        formControlName="description"
                      />
                    </td>
                    <td>
                      <mat-icon (click)="addRow()">add</mat-icon>
                      <mat-icon (click)="remove(i)">delete</mat-icon>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="formbutton">
          <button type="submit" class="btn btn-primary" (click)="onNext()">Next</button>
        </div>
       
        <h3 *ngIf="this.next == true">Purchase Details</h3>
        <div class="card" *ngIf="this.next == true">
          <div class="form-row">
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
              <label for="pocode">Purchase Order Code<span class="requried-field">*</span></label>
              <input
                type="text"
                id="pocode"
                readonly
                placeholder="Enter pocode"
                class="form-control"
                formControlName="pocode"
              />
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
              <label for="supplierName">Supplier<span class="requried-field">*</span></label>
              <ng-autocomplete
                [data]="supplierName"
                [searchKeyword]="keyword4"
                placeholder="Enter the supplier Name"
                (selected)='selectEvent4($event)'
                (inputChanged)='onChangeSearch4($event)'
                (inputFocused)='onFocused4($event)'
                historyIdentifier="supplierName"
                [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>
              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.supplierName"></a>
              </ng-template>
              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
              <label for="orderDate">Order Date<span class="requried-field">*</span></label>
              <input
                type="date"
                id="orderDate"
                placeholder="Enter orderDate"
                class="form-control"
                formControlName="orderDate"
              />
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
              <label for="needDate">Need Date<span class="requried-field">*</span></label>
              <input
                type="date"
                id="needDate"
                placeholder="Enter needDate"
                class="form-control"
                formControlName="needDate"
              />
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
              <label for="projectName">Project<span class="requried-field">*</span></label>
              <ng-autocomplete
                [data]="projectName"
                [searchKeyword]="keyword1"
                placeholder="Enter the Category Name"
                (selected)='selectEvent1($event)'
                (inputChanged)='onChangeSearch1($event)'
                (inputFocused)='onFocused1($event)'
                historyIdentifier="projectName"
                [itemTemplate]="itemTemplate1"
                [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>
              <ng-template #itemTemplate1 let-item>
                <a [innerHTML]="item.projectName"></a>
              </ng-template>
              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
              <label for="siteNo">Site No<span class="requried-field">*</span></label>
              <select
                id="siteNo"
                name="siteNo"
                class="form-control"
                formControlName="siteNo"
              >
                <option value="" selected disabled>--Please select--</option>
                <option
                  *ngFor="let data of projectName"
                  [value]="data.siteNo"
                  >{{ data.siteNo }}
                </option>
              </select>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
              <label for="engineer">Engineer<span class="requried-field">*</span></label>
              <ng-autocomplete
                [data]="engineer"
                [searchKeyword]="keyword3"
                placeholder="Enter the Engineer"
                (selected)='selectEvent3($event)'
                (inputChanged)='onChangeSearch3($event)'
                (inputFocused)='onFocused3($event)'
                historyIdentifier="engineer"
                [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>
              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.engineer"></a>
              </ng-template>
              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
              <label for="totalProduct">Total Products<span class="requried-field">*</span></label>
              <input
                readonly
                id="totalProduct"
                placeholder="Enter totalProduct"
                class="form-control"
                formControlName="totalProduct"
              />
            </div>
          </div>
        </div>
        <div class="formbutton" *ngIf="this.next == true">
          <button type="submit" class="btn btn-primary" (click)="onSubmit()">Submit</button>
          <button type="button" class="btn btn-danger btn2" (click)="onCancel()">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
